import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TransferState, makeStateKey } from '@angular/core';
import { Observable, of } from 'rxjs';

export class TranslateBrowserLoader implements TranslateLoader {
  constructor(private http: HttpClient, private transferState: TransferState) {}

  getTranslation(lang: string): Observable<any> {
    const key = makeStateKey<any>('transfer-translate-' + lang);
    const storedTranslations = this.transferState.get(key, null);

    if (storedTranslations) {
      return of(storedTranslations);
    } else {
      const timestamp = new Date().getTime();
      return this.http.get(`/assets/translations/${lang}.json?v=${timestamp}`);
    }
  }
}