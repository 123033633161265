import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { isPlatformBrowser } from '@angular/common';

interface UtmEntry {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_term: string | null;
  utm_content: string | null;
  timestamp: string;
}

interface ClickIds {
  gclid: string | null;
  fbclid: string | null;
  ttclid: string | null;
  timestamp: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
  ],
})
export class AppComponent {
  constructor(
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    // Only access localStorage in browser environment
    if (isPlatformBrowser(this.platformId)) {
      // Initialize session ID if not exists
      if (!localStorage.getItem('session_id')) {
        const sessionId = uuidv4();
        localStorage.setItem('session_id', sessionId);
      }

      // Get params
      this.route.queryParams.subscribe(params => {
        // Get UTM parameters
        const utmParams = {
          utm_source: params['utm_source'] || null,
          utm_medium: params['utm_medium'] || null,
          utm_campaign: params['utm_campaign'] || null,
          utm_term: params['utm_term'] || null,
          utm_content: params['utm_content'] || null
        };

        // Handle click IDs
        const clickIds = {
          gclid: params['gclid'] || null,
          fbclid: params['fbclid'] || null,
          ttclid: params['ttclid'] || null,
          timestamp: new Date().toISOString()
        };

        // Only process if at least one UTM parameter exists
        if (Object.values(utmParams).some(value => value)) {
          this.addUtmEntry(utmParams);
        }

        // Process click IDs if any exist
        if (Object.values(clickIds).some(value => value && value !== clickIds.timestamp)) {
          this.updateClickIds(clickIds);
        }

        // Log current session and UTM entries
        /* 
        console.log('Current Session ID:', localStorage.getItem('session_id'));
        console.log('Current UTM Entries:', localStorage.getItem('utm_entries') ? 
          JSON.parse(localStorage.getItem('utm_entries')!) : []);
        */

        // Log current click IDs
        /* 
        console.log('Current Click IDs:', localStorage.getItem('click_ids') ? 
          JSON.parse(localStorage.getItem('click_ids')!) : []);
        */
      });
    }
  }

  private addUtmEntry(utmParams: Partial<UtmEntry>) {
    // Get existing entries or initialize empty array
    const existingEntriesJson = localStorage.getItem('utm_entries');
    const existingEntries: UtmEntry[] = existingEntriesJson ? JSON.parse(existingEntriesJson) : [];

    // Create new entry with timestamp
    const newEntry: UtmEntry = {
      ...utmParams,
      timestamp: new Date().toISOString()
    } as UtmEntry;

    // Check if this combination already exists
    const exists = existingEntries.some(entry => 
      entry.utm_source === newEntry.utm_source &&
      entry.utm_medium === newEntry.utm_medium &&
      entry.utm_campaign === newEntry.utm_campaign &&
      entry.utm_term === newEntry.utm_term &&
      entry.utm_content === newEntry.utm_content
    );

    // Only add if it's a unique combination
    if (!exists) {
      existingEntries.push(newEntry);
      localStorage.setItem('utm_entries', JSON.stringify(existingEntries));
    }
  }

  private updateClickIds(clickIds: ClickIds) {
    localStorage.setItem('click_ids', JSON.stringify(clickIds));
  }
}
