import { Routes } from '@angular/router';
//To be fixed
export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./v1/modules/shell/shell.routes').then((m) => m.SHELL_ROUTES),
  },
  {
    path: 'en',
    loadChildren: () =>
      import('./v1/modules/shell/shell.routes').then((m) => m.SHELL_ROUTES),
  },
  {
    path: 'fr',
    loadChildren: () =>
      import('./v1/modules/shell/shell.routes').then((m) => m.SHELL_ROUTES),
  },
  {
    path: 'de',
    loadChildren: () =>
      import('./v1/modules/shell/shell.routes').then((m) => m.SHELL_ROUTES),
  },
  {
    path: 'ar',
    loadChildren: () =>
      import('./v1/modules/shell/shell.routes').then((m) => m.SHELL_ROUTES),
  },
  {
    path: 'es',
    loadChildren: () =>
      import('./v1/modules/shell/shell.routes').then((m) => m.SHELL_ROUTES),
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full',
  }
];

/*

SUPPOSED TO BE USED

// app.routes.ts
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./v1/modules/shell/shell.routes').then((m) => m.SHELL_ROUTES),
  },
  // Add other routes as needed
];

*/

/*
OLD ROUTES

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./v1/modules/shell/shell.module').then(m => m.ShellModule),
  },
  {
    path: 'en',
    loadChildren: () => import('./v1/modules/shell/shell.module').then(m => m.ShellModule),
  },
  {
    path: 'fr',
    loadChildren: () => import('./v1/modules/shell/shell.module').then(m => m.ShellModule),
  },
  {
    path: 'de',
    loadChildren: () => import('./v1/modules/shell/shell.module').then(m => m.ShellModule),
  },
  {
    path: 'ar',
    loadChildren: () => import('./v1/modules/shell/shell.module').then(m => m.ShellModule),
  },
  {
    path: 'es',
    loadChildren: () => import('./v1/modules/shell/shell.module').then(m => m.ShellModule),
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking' })],


*/